export default {
  api: {
    BASE_URL: process.env.REACT_APP_API_BASE_URL
  },
  aws: {
    REGION: process.env.REACT_APP_REGION
  },
  cognito: {
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  },
  s3: {
    PUBLIC_BUCKET: process.env.REACT_APP_S3_PUBLIC_BUCKET
  }
}
