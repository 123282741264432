import React, { useState, useEffect } from 'react'
import api from '../lib/api'
import { Modal, Form, Input, Upload, Icon, message } from 'antd'
import { nanoid } from 'nanoid'
import axios from 'axios'
import config from '../config'
import { USER_STATUSES } from '../share/constants'
import { removeHtmlTags } from '../share/helpers'
import TextInput from './TextInput'
import { sanitizeValue } from './../share/helpers'

const { Dragger } = Upload
const { TextArea } = Input

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const ProfessionalDeputySetupModal = ({
  visible,
  setVisible,
  form,
  isDefault,
  fetchUsers,
  fetchDeputies,
  selectedDeputy,
  admins,
  users,
  listProfessionalDeputy,
  setSelectedDeputy
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const [isSaving, setIsSaving] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const userEmails = users
    .map(user => user.email)
    .filter(email => email !== undefined)

  const pdEmails = listProfessionalDeputy.reduce((accumulator, pd) => {
    if (pd.accounts?.length) {
      pd.accounts.forEach(account => accumulator.push(account.email))
    }
    return accumulator
  }, [])

  const listEmail = [...new Set([...userEmails, ...pdEmails])]

  useEffect(() => {
    if (selectedDeputy?.id) {
      setImageUrl(
        `https://s3-${config.aws.REGION}.amazonaws.com/${config.s3.PUBLIC_BUCKET}/logo/${selectedDeputy.id}`
      )
    }
  }, [selectedDeputy])

  const onSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      removeHtmlTags(values)

      setIsSaving(true)
      const professionalDeputyId = nanoid(8)
      const data = {
        emailAddresses: [values.email1, values.email2],
        isDefault,
        name: values.name,
        description: values.description,
        isEdit: selectedDeputy?.id ? true : false
      }

      try {
        if (!selectedDeputy?.id) {
          await api.addProfessionalDeputy(
            JSON.stringify({
              id: professionalDeputyId,
              ...data
            })
          )
          await api.addAdmin({
            email: values.adminEmail,
            professionalDeputyId
          })
        } else {
          await api.updateProfessionalDeputy({
            id: selectedDeputy.id,
            ...data
          })

          const adminEmail =
            selectedDeputy?.adminAccounts &&
            selectedDeputy.adminAccounts[0]?.email

          if (values.adminEmail !== adminEmail) {
            await api.updateAdmin({
              oldEmail: adminEmail,
              newEmail: values.adminEmail,
              professionalDeputyId: selectedDeputy.id
            })
          }
        }

        if (!isDefault && values.logo?.length && values.logo[0].originFileObj) {
          const urlRes = await api.getSignedUrl(
            'logo',
            selectedDeputy?.id || professionalDeputyId,
            'putObject'
          )

          const file = values.logo[0].originFileObj
          await axios.put(urlRes.data, file, {
            headers: {
              'Content-Type': file.type
            }
          })
        }

        setIsSaving(false)
        message.success(
          `Successfully ${
            selectedDeputy?.id ? 'updated' : 'added'
          } professional deputy`
        )
        fetchUsers()
        fetchDeputies()
        closeModal()
      } catch (err) {
        setIsSaving(false)
        message.error(
          err.message ||
            `Failed to ${
              selectedDeputy?.id ? 'updated' : 'added'
            } professional deputy`
        )
        console.log(err)
      }
    })
  }

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
    setImageUrl('')
    setSelectedDeputy({})
  }

  const draggerProps = {
    beforeUpload: () => {
      // this is for preventing the upload action, so we can customize the upload flow and upload to S3 later when the form is saved
      return false
    },
    onChange: info => {
      if (info.file.status === 'removed') {
        setImageUrl(
          selectedDeputy?.id
            ? `https://s3-${config.aws.REGION}.amazonaws.com/${config.s3.PUBLIC_BUCKET}/logo/${selectedDeputy.id}`
            : ''
        )
      } else if (
        info.file.type === 'image/jpeg' ||
        info.file.type === 'image/png'
      ) {
        getBase64(info.file, imageUrl => {
          setImageUrl(imageUrl)
        })
      }
    }
  }

  const normFile = e => {
    // Temporary only allow uploading a single file at once (the last selected file) for simplicity,
    // may consider adding support for uploading multiple files later
    if (Array.isArray(e)) {
      return e.slice(-1)
    }
    return e && e.fileList.slice(-1)
  }

  return (
    <Modal
      title="Professional Deputy setup"
      visible={visible}
      maskClosable={false}
      okText="Save"
      onOk={onSave}
      onCancel={closeModal}
      okButtonProps={{ loading: isSaving }}
    >
      <Form>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: isDefault
              ? 'Watiga Trust'
              : selectedDeputy?.professionalDeputyName,
            rules: [
              {
                required: true,
                message: 'Enter name'
              }
            ]
          })(
            <TextInput
              name="name"
              setFieldsValue={setFieldsValue}
              disabled={isDefault}
            />
          )}
        </Form.Item>
        {!isDefault && (
          <>
            <Form.Item label="Logo">
              {getFieldDecorator('logo', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                rules: [
                  {
                    required: !selectedDeputy?.id,
                    message: 'Please select a file to upload'
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (!value || !value.length) {
                        callback()
                        return
                      }

                      const fileType = value[0]?.originFileObj.type
                      const isJpgOrPng =
                        fileType === 'image/jpeg' || fileType === 'image/png'

                      if (!isJpgOrPng) {
                        callback('You can only upload JPG/PNG file!')
                      } else {
                        callback()
                      }
                    }
                  }
                ]
              })(
                <Dragger {...draggerProps}>
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Professional Deputy logo"
                      style={{ width: 300 }}
                    />
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <Icon type="upload" />
                      </p>
                      <p className="ant-upload-hint">
                        Click or drag file to this area to upload
                      </p>
                    </>
                  )}
                </Dragger>
              )}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                initialValue: selectedDeputy?.description,
                rules: [
                  {
                    required: true,
                    message: 'Enter description'
                  }
                ]
              })(
                <TextArea
                  onBlur={e =>
                    setFieldsValue({
                      description: sanitizeValue(e.target.value).trim()
                    })
                  }
                  rows={4}
                  maxLength={2000}
                />
              )}
            </Form.Item>
          </>
        )}
        <Form.Item label="Deputy email address 1">
          {getFieldDecorator('email1', {
            initialValue: selectedDeputy?.accounts
              ? selectedDeputy.accounts[0]?.email
              : '',
            rules: [
              {
                type: 'email',
                message: 'Enter a valid email address!'
              },
              {
                required: true,
                message: 'Enter email address'
              },
              {
                validator: async (rule, value) => {
                  const pdEmail =
                    selectedDeputy?.accounts &&
                    selectedDeputy.accounts[0]?.email
                  if (
                    value &&
                    selectedDeputy.id &&
                    listEmail.find(
                      email => email === value && email !== pdEmail
                    )
                  ) {
                    throw new Error(
                      'An account with the given email already exists!'
                    )
                  }
                  if (value && getFieldValue('email2') === value) {
                    throw new Error('Duplicate email!')
                  }
                }
              }
            ]
          })(<TextInput placeholder="email@example.com" />)}
        </Form.Item>
        <Form.Item label="Deputy email address 2">
          {getFieldDecorator('email2', {
            initialValue: selectedDeputy?.accounts
              ? selectedDeputy.accounts[1]?.email
              : '',
            rules: [
              {
                type: 'email',
                message: 'Enter a valid email address!'
              },
              {
                required: true,
                message: 'Enter email address'
              },
              {
                validator: async (rule, value) => {
                  const pdEmail =
                    selectedDeputy?.accounts &&
                    selectedDeputy.accounts[1]?.email
                  if (
                    value &&
                    selectedDeputy.id &&
                    listEmail.find(
                      email => email === value && email !== pdEmail
                    )
                  ) {
                    throw new Error(
                      'An account with the given email already exists!'
                    )
                  }
                  if (value && getFieldValue('email1') === value) {
                    throw new Error('Duplicate email!')
                  }
                }
              }
            ]
          })(<TextInput placeholder="email@example.com" />)}
        </Form.Item>
        <Form.Item label="Admin email address">
          {getFieldDecorator('adminEmail', {
            initialValue: selectedDeputy?.adminAccounts
              ? selectedDeputy?.adminAccounts[0]?.email
              : '',
            rules: [
              {
                type: 'email',
                message: 'Enter a valid email address!'
              },
              {
                required: true,
                message: 'Enter email address'
              },
              {
                validator: (rule, value, callback) => {
                  const adminEmail =
                    selectedDeputy?.adminAccounts &&
                    selectedDeputy.adminAccounts[0]?.email
                  if (
                    value &&
                    admins.find(
                      a => a.email === value && a.email !== adminEmail
                    )
                  ) {
                    callback('An account with the given email already exists.')
                  } else {
                    callback()
                  }
                }
              }
            ]
          })(
            <TextInput
              placeholder="email@example.com"
              disabled={
                !!(
                  selectedDeputy?.adminAccounts &&
                  admins.find(
                    a =>
                      a.status === USER_STATUSES.CONFIRMED &&
                      a.email === selectedDeputy.adminAccounts[0]?.email
                  )
                )
              }
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const WrappedProfessionalDeputySetupForm = Form.create({
  name: 'ProfessionalDeputySetup'
})(ProfessionalDeputySetupModal)
export default WrappedProfessionalDeputySetupForm
