import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import './App.css'
import Login from './components/Login'
import Home from './components/Home'
// import Settings from './components/common/Settings'
// import ForgotPassword from './components/common/ForgotPassword'
import {
  getCognitoUser,
  getUserSession,
  isValidSession,
  getUserAttributeValue
} from './lib/cognito'
import { setApiInterceptor, clearApiInterceptor } from './lib/api'
import AuthLayout from './components/AuthLayout'
import UnauthLayout from './components/UnauthLayout'
import Admins from './components/Admins'
import ProfessionalDeputies from './components/ProfessionalDeputies'
import BillingReport from './components/BillingReport'
import PromotionCodes from './components/PromotionCodes'
import { MFA_TYPES } from './share/constants'
import config from './config'
import Employees from './components/Employees'

export const AuthContext = React.createContext()

export default function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})

  useEffect(() => {
    onLoad()
  }, [])

  function onLoad() {
    const user = getCognitoUser()
    if (!user) {
      setIsAuthenticating(false)
      return
    }

    getUserSession(user, (err, session) => {
      if (err || !isValidSession(session)) {
        user.signOut()
        localStorage.clear()
        setIsAuthenticated(false)
        clearApiInterceptor()
        return
      }

      setUser(user)

      user.getUserData((err, res) => {
        if (err) {
          return
        }
        const bypassMFA = getUserAttributeValue(
          res.UserAttributes,
          'custom:bypass_mfa'
        )

        if (
          res?.PreferredMfaSetting === MFA_TYPES.TOTP ||
          (config.env !== 'prod' && bypassMFA === 'true')
        ) {
          setApiInterceptor(user)
          setIsAuthenticated(true)
        } else {
          user.signOut()
          localStorage.clear()
          setIsAuthenticated(false)
          clearApiInterceptor()
        }
      })
    })

    setIsAuthenticating(false)
  }

  const resetAppStates = () => {
    setIsAuthenticated(false)
    setUser({})
  }

  function PrivateRoute({ children, isAdminRoute, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location, history }) =>
          isAuthenticated ? (
            <AuthContext.Provider value={{ user }}>
              <AuthLayout
                resetAppStates={resetAppStates}
                location={location}
                history={history}
                isAdminRoute={isAdminRoute}
              >
                {children}
              </AuthLayout>
            </AuthContext.Provider>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }

  return (
    !isAuthenticating && (
      <Router>
        <Switch>
          {/* <Route path="/resetpassword">
            <UnauthLayout header={signupHeader}>
              <ForgotPassword />
            </UnauthLayout>
          </Route> */}

          <Route path="/login">
            {isAuthenticated ? (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: '/login' }
                }}
              />
            ) : (
              <UnauthLayout>
                <Login
                  setIsAuthenticated={setIsAuthenticated}
                  setUser={setUser}
                />
              </UnauthLayout>
            )}
          </Route>

          {/* <PrivateRoute path="/settings">
            <Settings>
              <Profile />
            </Settings>
          </PrivateRoute> */}
          <PrivateRoute exact path="/admins">
            <Admins />
          </PrivateRoute>
          <PrivateRoute exact path="/employees">
            <Employees />
          </PrivateRoute>
          <PrivateRoute exact path="/professional-deputies">
            <ProfessionalDeputies />
          </PrivateRoute>
          <PrivateRoute exact path="/billing-report">
            <BillingReport />
          </PrivateRoute>
          <PrivateRoute exact path="/promotion-codes" isAdminRoute>
            <PromotionCodes />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
        </Switch>
      </Router>
    )
  )
}
