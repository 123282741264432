import React, { useState } from 'react'
import { Form, Modal, Alert, message } from 'antd'
import api from '../lib/api'
import AddMultipleFields from './AddMultipleFields'

const compareWithDelegatedEmails = (rule, value, callback, delegatedEmails) => {
  if (value && delegatedEmails.find(d => d === value)) {
    callback('User account with this email address was already delegated.')
  } else {
    callback()
  }
}

const compareWithPdEmails = (rule, value, callback, pdEmails) => {
  if (value && pdEmails.find(d => d === value)) {
    callback('Cannot delegate access to main Professional deputy accounts.')
  } else {
    callback()
  }
}

function DelegateAccessModal({
  visible,
  setVisible,
  selectedDeputy,
  fetchDeputies,
  professionalDeputies,
  form
}) {
  const [err, setErr] = useState('')
  const [isSending, setIsSending] = useState(false)
  const { getFieldDecorator } = form
  const pdEmails = professionalDeputies.reduce(
    (emails, pd) => emails.concat(pd.accounts?.map(a => a.email) || []),
    []
  )

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setIsSending(true)
      try {
        await api.delegatePdAccess(
          JSON.stringify({
            emails: [...new Set(values.emails.map(e => e.email))],
            professionalDeputyId: selectedDeputy.id
          })
        )
        message.success('Successfully delegated access')
        fetchDeputies()
        setIsSending(false)
        reset()
      } catch (error) {
        setErr(error.message || 'Failed to delegate access')
        setIsSending(false)
        console.log(error)
      }
    })
  }

  const reset = () => {
    form.resetFields()
    setVisible(false)
    setErr('')
  }
  return (
    <Modal
      title="Delegate access"
      visible={visible}
      okText="Save"
      okButtonProps={{
        loading: isSending
      }}
      onOk={handleSave}
      onCancel={reset}
      width={700}
    >
      <>
        <Form>
          <Form.Item label="Email addresses">
            <AddMultipleFields
              name="emails"
              title="email address"
              fields={[{ key: 'email', placeholder: 'email@example.com' }]}
              getFieldDecorator={getFieldDecorator}
              customRules={[
                {
                  fieldKey: 'email',
                  rules: [
                    {
                      type: 'email',
                      message: 'Enter a valid email address'
                    },
                    {
                      validator: (rule, value, callback) =>
                        compareWithDelegatedEmails(
                          rule,
                          value,
                          callback,
                          selectedDeputy.delegatedAccounts?.map(
                            da => da.email
                          ) || []
                        )
                    },
                    {
                      validator: (rule, value, callback) =>
                        compareWithPdEmails(rule, value, callback, pdEmails)
                    }
                  ]
                }
              ]}
            />
          </Form.Item>
        </Form>
        {err && (
          <Alert
            message={err}
            type="error"
            closable
            afterClose={() => setErr('')}
          />
        )}
      </>
    </Modal>
  )
}

const WrappedAddProfessinalDeputiesModalForm = Form.create({
  name: 'DelegateAccessModal'
})(DelegateAccessModal)

export default WrappedAddProfessinalDeputiesModalForm
