import React from 'react'
import logo from '../assets/white.svg'

export default function UnauthLayout({ children }) {
  return (
    <div className="unauth-wrapper">
      <div className="unauth-content">{children}</div>
      <div className="unauth-bg">
        <div className="unauth-bg-img"></div>
      </div>
      <div className="logo">
        <img src={logo} alt="vaultbox logo" />
      </div>
    </div>
  )
}
