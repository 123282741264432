import React, { useEffect, useState, useCallback, useContext } from 'react'
import api from '../lib/api'
import { PageHeader, Alert, Table, Button } from 'antd'
import { AdminContext } from './AuthLayout'
import AddEmployeeModal from './AddEmployeeModal'

export default function Home() {
  const [employees, setEmployees] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [addEmployeeModalVisible, setAddEmployeeModalVisible] = useState(false)
  const [error, setError] = useState('')

  const { professionalDeputyId } = useContext(AdminContext)

  const fetchEmployees = useCallback(async () => {
    if (professionalDeputyId) {
      try {
        setIsLoading(true)
        const res = await api.getEmployees({ professionalDeputyId })
        if (res.data?.length) setEmployees(res.data)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setError(error.message)
        setIsLoading(false)
      }
    }
  }, [professionalDeputyId])

  useEffect(() => {
    fetchEmployees()
  }, [fetchEmployees])

  const columns = [
    {
      key: 'id',
      title: 'Referral code',
      dataIndex: 'id'
    },
    {
      key: 'employeeName',
      title: 'Employee name',
      dataIndex: 'employeeName'
    },
    {
      key: 'refereeUsers',
      title: 'Referred users',
      dataIndex: 'refereeUsers',
      render: items => items?.map(re => re.email).join('; ')
    }
  ]

  return (
    <>
      <PageHeader
        title="Employees"
        extra={
          <Button
            type="primary"
            onClick={() => setAddEmployeeModalVisible(true)}
          >
            Add employee
          </Button>
        }
      />
      <div style={{ padding: 20 }}>
        {error && <Alert type="error" showIcon message={error} closable />}
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={employees}
        />
      </div>

      <AddEmployeeModal
        visible={addEmployeeModalVisible}
        setVisible={setAddEmployeeModalVisible}
        fetchEmployees={fetchEmployees}
      />
    </>
  )
}
