import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback
} from 'react'
import api from '../lib/api'
import {
  PageHeader,
  Alert,
  Table,
  Tooltip,
  Icon,
  Button,
  Tag,
  message,
  Modal
} from 'antd'
import moment from 'moment'
import AccessAttemptsModal from './AccessAttemptsModal'
import SearchForm from './SearchForm'
import { formatBytes, includesCaseInsensitive } from '../share/helpers'
import { AdminContext } from './AuthLayout'
import InviteUsersModal from './InviteUsersModal'
import { groupBy } from 'lodash'
import ExportExcel from './ExportExcel'

export default function Home() {
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState('')
  const [inviteUsersModalVisible, setInviteUsersModalVisible] = useState(false)
  // const [isDefault, setIsDefault] = useState(true)
  // const [professionalDeputies, setProfessionalDeputies] = useState([])
  const [products, setProducts] = useState([])
  const [discountCodeGroups, setDiscountCodeGroups] = useState([])

  const searchFormRef = useRef()
  const { professionalDeputyId } = useContext(AdminContext)

  const fetchUsersData = useCallback(() => {
    // initally professionalDeputyId is undefined, we would wait until it has value to start fetching admins
    if (professionalDeputyId === undefined) return

    setIsLoading(true)
    api
      .listUsers(professionalDeputyId === '' ? {} : { professionalDeputyId })
      .then(res => {
        if (res.data.message) throw Error(res.data.message)

        if (res.data.length) {
          const users = res.data
            .map(user => {
              return {
                id: user.id,
                name: user.fullName,
                email: user.email,
                createDate: user.createdAt,
                deputies: user.deputies
                  ?.filter(d => d.id)
                  .map(d => d.email)
                  .join(', '),
                unlockStatus: user.unlockedShares
                  ? `${user.unlockedShares.length}/2`
                  : '0/2',
                lastLogin: user.lastLogin,
                appointedProfessionalDeputyId:
                  user.appointedProfessionalDeputyId,
                marketingFromVaultbox: user.marketingFromVaultbox,
                marketingFromThirdParties: user.marketingFromThirdParties,
                countryName: user.countryName,
                appliedPromotionCodes: user.appliedPromotionCodes || [],
                usedStorage: user.usedStorage > 0 ? user.usedStorage : undefined,
                phoneNumber: user.phoneNumber,
                signUpMethod: user.id.includes("google") ? "Google" : user.id.includes("facebook") ? "Facebook" : "Email", 
              }
            })
            .filter(user => user.email)

          setUsers(users)
          setFilteredUsers(users)
        }

        setIsLoading(false)
      })
      .catch(err => {
        setError(err.message || 'Failed to get users')
        setIsLoading(false)
        console.log(err)
      })

    const fetchAdditionalData = async () => {
      try {
        let isDefault
        const pdRes = await api.getProfessionalDeputy(professionalDeputyId)
        if (pdRes.data.Item) {
          isDefault = pdRes.data.Item.isDefault
          // setIsDefault(isDefault)
        }

        const pricesRes = await api.getPricesByProfessionalDeputy(
          professionalDeputyId === '' || isDefault
            ? {}
            : { professionalDeputyId }
        )
        const groupedByDiscountCode = groupBy(
          pricesRes.data,
          price => price.metadata.discountCode || 'default'
        )

        let discountCodeGroups = []
        for (const discountCode in groupedByDiscountCode) {
          const groupedByProduct = groupBy(
            groupedByDiscountCode[discountCode],
            item => item.product
          )
          discountCodeGroups.push({
            code: discountCode,
            data: groupedByProduct
          })
        }
        setDiscountCodeGroups(discountCodeGroups)
      } catch (err) {
        console.log(err)
        setError(err.message || 'Failed to get additional data')
      }
    }

    fetchAdditionalData()

    // api
    //   .getProfessionalDeputies()
    //   .then(res => {
    //     if (res.data.Items?.length) {
    //       setProfessionalDeputies(res.data.Items)
    //     }
    //   })
    //   .catch(err => console.log(err))
  }, [professionalDeputyId])

  useEffect(() => {
    fetchUsersData()
  }, [fetchUsersData])

  useEffect(() => {
    api
      .getProducts()
      .then(res => {
        if (res.data.data?.length) setProducts(res.data.data)
      })
      .catch(err => {
        setError(err.message || 'Failed to get products')
      })
  }, [])

  const handleRemoveDiscount = async (userId, code) => {
    try {
      await api.removeAppliedDiscount(
        JSON.stringify({
          userId,
          code
        })
      )
      fetchUsersData()
      message.success('Successfully removed the discount')
    } catch (error) {
      console.log(error)
      message.error(error.message || 'Failed to remove the discount')
    }
  }

  const confirmRemoveDiscount = (userId, code) => {
    Modal.confirm({
      title: 'Remove the currently applied discount',
      content:
        'Are you sure to remove the currently applied discount on this subscription?',
      onOk: async () => await handleRemoveDiscount(userId, code),
      okText: 'Yes',
      cancelText: 'No'
    })
  }

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name'
    },
    {
      key: 'email',
      title: 'Email address',
      dataIndex: 'email'
    },
    {
      key: 'phone',
      title: 'Phone number',
      dataIndex: 'phoneNumber'
    },
    {
      key: 'createDate',
      title: 'Date of creation',
      dataIndex: 'createDate',
      sorter: (a, b) => moment(a.createDate) - moment(b.createDate),
      render: text => text && moment(text).format('LLL'),
      exportRender: text => text && new Date(text),
    },
    {
      key: 'signUpMethod',
      title: 'Sign up method',
      dataIndex: 'signUpMethod'
    },
    // {
    //   key: 'professionalDeputy',
    //   title: 'Professional deputy',
    //   dataIndex: 'appointedProfessionalDeputyId',
    //   render: text =>
    //     text &&
    //     professionalDeputies.find(d => d.id === text)?.professionalDeputyName
    // },
    {
      key: 'deputies',
      title: 'Deputies',
      dataIndex: 'deputies'
    },
    {
      key: 'unlockStatus',
      title: 'Account unlock status',
      dataIndex: 'unlockStatus'
    },
    {
      key: 'marketingFromVaultbox',
      title: 'Marketing from vaultbox',
      dataIndex: 'marketingFromVaultbox',
      render: text => (text === true ? 'Yes' : 'No')
    },
    {
      key: 'marketingFromThirdParties',
      title: 'Marketing from 3rd parties',
      dataIndex: 'marketingFromThirdParties',
      render: text => (text === true ? 'Yes' : 'No')
    },
    {
      key: 'countryName',
      title: 'Country / Territory',
      dataIndex: 'countryName'
    },
    {
      key: 'appliedPromotionCodes',
      title: 'Applied promotion codes',
      dataIndex: 'appliedPromotionCodes',
      render: (codes, record) => {
        const now = moment().unix()
        return codes.map(item => (
          <p
            className="d-flex"
            style={{ alignItems: 'center' }}
            key={item.code}
          >
            <span className="m-r-5">{item.code}</span>
            {((item.start < now && now < item.end) ||
              (item.duration === 'forever' && item.end === null)) && (
              <>
                <Tag color="green">Active</Tag>
                <Tooltip
                  title="Remove the currently applied discount"
                  overlayStyle={{ maxWidth: 280 }}
                >
                  <Icon
                    type="minus-circle"
                    onClick={() => confirmRemoveDiscount(record.id, item.code)}
                  />
                </Tooltip>
              </>
            )}
          </p>
        ))
      },
      exportWidth: 40,
      style: { alignment: { wrapText: true } },
      exportRender: codes => codes.map(item => item.code).join('\r\n')
    },
    {
      key: 'usedStorage',
      title: 'Used Storage',
      dataIndex: 'usedStorage',
      sorter: (a, b, sortOrder) => {
        if (sortOrder) {
          if (a.usedStorage && b.usedStorage) {
            return a.usedStorage - b.usedStorage
          }
          if (a.usedStorage) {
            return sortOrder === 'ascend' ? -1 : 1
          }
          if (b.usedStorage) {
            return sortOrder === 'ascend' ? 1 : -1
          }
        } else {
          return
        }
      },
      render: text => text && formatBytes(text),
      exportRender: text => text && Math.round((text / 1024) * 100) / 100
    },
    {
      key: 'lastLogin',
      title: 'Last logged in',
      dataIndex: 'lastLogin',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.lastLogin) - moment(b.lastLogin),
      render: text => text && moment(text).format('LLL'),
      exportRender: text => text && new Date(text),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Tooltip
          title="Access attempts history"
          placement="topRight"
          arrowPointAtCenter
        >
          <Icon type="history" onClick={() => viewAccessAttempts(record.id)} />
        </Tooltip>
      )
    }
  ]

  const viewAccessAttempts = userId => {
    setSelectedUserId(userId)
    setModalVisible(true)
  }

  const handleSearch = () => {
    searchFormRef.current.validateFields((err, values) => {
      if (err) return

      let filteredUsers = users

      if (values.name) {
        filteredUsers = filteredUsers.filter(u =>
          includesCaseInsensitive(u.name, values.name)
        )
      }

      if (values.email) {
        filteredUsers = filteredUsers.filter(u =>
          includesCaseInsensitive(u.email, values.email)
        )
      }

      if (values.deputy) {
        filteredUsers = filteredUsers.filter(u =>
          includesCaseInsensitive(u.deputies, values.deputy)
        )
      }

      if (values.createDateFrom || values.createDateTo) {
        if (values.createDateFrom) {
          filteredUsers = filteredUsers.filter(u =>
            moment(u.createDate).isSameOrAfter(
              values.createDateFrom.startOf('day')
            )
          )
        }

        if (values.createDateTo) {
          filteredUsers = filteredUsers.filter(u =>
            moment(u.createDate).isSameOrBefore(
              values.createDateTo.endOf('day')
            )
          )
        }
      }

      if (values.lastLoginFrom || values.lastLoginTo) {
        if (values.lastLoginFrom) {
          filteredUsers = filteredUsers.filter(u =>
            moment(u.lastLogin).isSameOrAfter(
              values.lastLoginFrom.startOf('day')
            )
          )
        }

        if (values.lastLoginTo) {
          filteredUsers = filteredUsers.filter(u =>
            moment(u.lastLogin).isSameOrBefore(values.lastLoginTo.endOf('day'))
          )
        }
      }

      if (values.marketingFromVaultbox) {
        filteredUsers = filteredUsers.filter(u => u.marketingFromVaultbox)
      }

      if (values.marketingFromThirdParties) {
        filteredUsers = filteredUsers.filter(u => u.marketingFromThirdParties)
      }

      setFilteredUsers(filteredUsers)
    })
  }

  const resetFields = () => {
    searchFormRef && searchFormRef.current.resetFields()
    setFilteredUsers(users)
  }

  return (
    <>
      <PageHeader
        title="Users"
        extra={
          <>
            <ExportExcel
              fileName={'Users'}
              columns={columns}
              sourceData={filteredUsers}
            />
            <Button
              type="primary"
              onClick={() => setInviteUsersModalVisible(true)}
            >
              Invite users
            </Button>
          </>
        }
      />
      <div style={{ padding: 20 }}>
        {error && <Alert type="error" showIcon message={error} closable />}
        <SearchForm
          ref={searchFormRef}
          handleSearch={handleSearch}
          resetFields={resetFields}
        />
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={filteredUsers}
          scroll={{ x: true }}
        />
      </div>
      <AccessAttemptsModal
        visible={modalVisible}
        setVisible={setModalVisible}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
      />
      <InviteUsersModal
        visible={inviteUsersModalVisible}
        setVisible={setInviteUsersModalVisible}
        discountCodeGroups={discountCodeGroups}
        products={products}
      />
    </>
  )
}
