import React, { useState, useEffect, useContext } from 'react'
import { Layout, Icon, Tooltip, Popconfirm, Menu, Modal } from 'antd'
import {
  getUserAttributeValue,
  getUserSession,
  isValidSession
} from '../lib/cognito'
import { AuthContext } from '../App'
import logo from '../assets/white.svg'
import { Link, Redirect } from 'react-router-dom'
import { clearApiInterceptor } from '../lib/api'

const { Content, Sider } = Layout

export const AdminContext = React.createContext()

var timeoutModal

export default function AuthLayout({ children, isAdminRoute, ...rest }) {
  const { user } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [professionalDeputyId, setProfessionalDeputyId] = useState()
  const { resetAppStates, location } = rest

  useEffect(() => {
    if (!user || !user.getUserData) {
      return
    }

    user.getUserData(
      (err, data) => {
        if (err) {
          console.log('Failed to get user data: ', err)
          return
        }

        const email = getUserAttributeValue(data.UserAttributes, 'email')
        const professionalDeputyId = getUserAttributeValue(
          data.UserAttributes,
          'custom:professional_deputy'
        )
        setProfessionalDeputyId(professionalDeputyId)

        setEmail(email)
      },
      { bypassCache: true }
    )

    const onWindowFocus = () =>
      getUserSession(user, (err, session) => {
        if (err || !isValidSession(session)) {
          user.signOut()
          localStorage.clear()

          if (!timeoutModal) {
            timeoutModal = Modal.info({
              title: 'Session timeout',
              content: 'Please login again to continue',
              onOk() {
                Modal.destroyAll()
                window.location.reload()
              }
            })
          }
        }
      })

    window.addEventListener('focus', onWindowFocus)
    return () => {
      window.removeEventListener('focus', onWindowFocus)
    }
  }, [user])

  const handleSignOut = () => {
    if (user != null) {
      user.signOut()
    }
    resetAppStates()
    localStorage.clear()
    clearApiInterceptor()
  }

  let adminMenuItems = [
    {
      title: 'Users',
      icon: 'user',
      url: '/'
    },
    {
      title: 'Admins',
      icon: 'user-add',
      url: `/admins`
    },
    {
      title: 'Billing report',
      icon: 'file-text',
      url: `/billing-report`
    },
    {
      title: professionalDeputyId
        ? 'Professional deputy info'
        : 'Professional deputies',
      icon: 'user',
      url: `/professional-deputies`
    }
  ]
  if (!professionalDeputyId) {
    adminMenuItems = adminMenuItems.concat([
      {
        title: 'Promotion codes',
        icon: 'notification',
        url: `/promotion-codes`
      }
    ])
  }

  if (professionalDeputyId) {
    adminMenuItems = adminMenuItems.concat([
      {
        title: 'Employees',
        icon: 'usergroup-add',
        url: `/employees`
      }
    ])
  }

  return !!professionalDeputyId && isAdminRoute ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location }
      }}
    />
  ) : (
    <AdminContext.Provider value={{ professionalDeputyId }}>
      <Layout style={{ minHeight: '100vh' }} className="page-wrapper">
        <Sider className="main-sider" style={{ paddingBottom: 80 }} width={220}>
          <div className="logo">
            <img src={logo} alt="vaultbox logo" style={{ height: 30 }} />
          </div>
          <div className="profile">
            <div>
              <h4>{email}</h4>
              <div className="profile-links">
                <Popconfirm
                  title="Are you sure you want to sign out?"
                  onConfirm={handleSignOut}
                  okText="Yes"
                  placement="right"
                >
                  <Tooltip title="Sign out">
                    <Icon type="logout" />
                  </Tooltip>
                </Popconfirm>
              </div>
            </div>
          </div>
          <Menu
            selectedKeys={[(location && location.pathname) || '/']}
            mode="inline"
          >
            {adminMenuItems.map(item => (
              <Menu.Item key={item.url}>
                <Link to={item.url}>
                  <Icon type={item.icon} />
                  <span>{item.title}</span>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
          <div className="sider-footer">
            <div>
              &copy; 2020 <b>vaultbox</b>
            </div>
          </div>
        </Sider>
        <Layout style={{ backgroundColor: '#fff' }}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </AdminContext.Provider>
  )
}
