import React, { useState, useContext } from 'react'
import { Modal, Form, message } from 'antd'
import api from '../lib/api'
import { AdminContext } from './AuthLayout'
import { nanoid } from 'nanoid'
import TextInput from './TextInput'

const AddEmployeeModal = ({ visible, setVisible, fetchEmployees, form }) => {
  const { getFieldDecorator, setFieldsValue } = form
  const [isSaving, setIsSaving] = useState(false)
  const { professionalDeputyId } = useContext(AdminContext)

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setIsSaving(true)
        await api.addEmployee(
          JSON.stringify({
            id: nanoid(9),
            professionalDeputyId,
            employeeName: values.employeeName
          })
        )
        fetchEmployees()
        message.success('Successfully added employee!')
        setIsSaving(false)
        closeModal()
      } catch (error) {
        message.error(error.message || 'Failed to add employee!')
        console.log(error)
        setIsSaving(false)
      }
    })
  }

  return (
    <Modal
      title="Add employee"
      visible={visible}
      okText="Save"
      onOk={onSave}
      onCancel={closeModal}
      okButtonProps={{ loading: isSaving }}
    >
      <Form>
        <Form.Item label="Employee name">
          {getFieldDecorator('employeeName', {
            rules: [
              {
                required: true,
                message: 'Enter employee name'
              }
            ]
          })(<TextInput name="employeeName" setFieldsValue={setFieldsValue} />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}
const WrappedAddEmployeeForm = Form.create({ name: 'AddEmployeeModal' })(
  AddEmployeeModal
)
export default WrappedAddEmployeeForm
