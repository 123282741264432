import React, { useEffect, useState, useContext } from 'react'
import api from '../lib/api'
import {
  PageHeader,
  Alert,
  Table,
  Button,
  Popconfirm,
  Icon,
  Tooltip,
  message
} from 'antd'
import { getUserAttributeValue } from '../lib/cognito'
import moment from 'moment'
import AddAdminModal from './AddAdminModal'
import { AuthContext } from '../App'
import { AdminContext } from './AuthLayout'
import { USER_STATUSES } from '../share/constants'

export default function AdminUsers() {
  const { user } = useContext(AuthContext)
  const [admins, setAdmins] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [addAdminModalVisible, setAddAdminModalVisible] = useState(false)
  const { professionalDeputyId } = useContext(AdminContext)

  const fetchAdmins = async professionalDeputyId => {
    setIsLoading(true)
    try {
      const pdRes = await api.getProfessionalDeputies()
      let proDeputies = {}
      if (pdRes.data.Items?.length) {
        pdRes.data.Items.forEach(item => {
          proDeputies[item.id] = { ...item }
        })
      }

      const adminRes = await api.listAdmins(
        professionalDeputyId === '' ? {} : { professionalDeputyId }
      )

      if (adminRes.data.length) {
        const admins = adminRes.data.map(user => {
          return {
            id: user.Username,
            email: getUserAttributeValue(user.Attributes, 'email'),
            status: USER_STATUSES[user.UserStatus],
            createdDate: moment(user.UserCreateDate).format('LLL'),
            professionalDeputy:
              proDeputies[
                getUserAttributeValue(
                  user.Attributes,
                  'custom:professional_deputy'
                )
              ]?.professionalDeputyName
          }
        })
        setAdmins(admins)
      }
    } catch (err) {
      setError(err.message || 'Failed to get admins')
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // initally professionalDeputyId is undefined, we would wait until it has value to start fetching admins
    if (professionalDeputyId !== undefined) fetchAdmins(professionalDeputyId)
  }, [professionalDeputyId])

  const handleDeleteAdmin = async userId => {
    try {
      const res = await api.deleteAdmin(userId)
      if (res.data.message) throw Error(res.data.message)

      message.success('Successfully deleted admin')
      fetchAdmins(professionalDeputyId)
    } catch (err) {
      message.error('Failed to delete admin')
    }
  }

  const columns = [
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email'
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status'
    },
    {
      key: 'createdDate',
      title: 'Created date',
      dataIndex: 'createdDate'
    },
    {
      key: 'professionalDeputy',
      title: 'Professional deputy',
      dataIndex: 'professionalDeputy'
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <>
          {user.username !== record.id && (
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this admin?"
                okText="Yes"
                onConfirm={async () => await handleDeleteAdmin(record.id)}
                cancelText="No"
                arrowPointAtCenter
                placement="bottomRight"
              >
                <Icon theme="twoTone" twoToneColor="#eb4444" type="delete" />
              </Popconfirm>
            </Tooltip>
          )}
        </>
      )
    }
  ]

  return (
    <>
      <PageHeader
        title="Admins"
        extra={
          <Button type="primary" onClick={() => setAddAdminModalVisible(true)}>
            Add admin
          </Button>
        }
      />
      {error && <Alert type="error" showIcon message={error} closable />}
      <div style={{ padding: 20 }}>
        <Table
          rowKey="email"
          loading={isLoading}
          columns={columns}
          dataSource={admins}
        />
      </div>
      <AddAdminModal
        visible={addAdminModalVisible}
        setVisible={setAddAdminModalVisible}
        fetchAdmins={() => fetchAdmins(professionalDeputyId)}
      />
    </>
  )
}
