import React, { useEffect, useState, useContext } from 'react'
import {
  PageHeader,
  Alert,
  Button,
  Descriptions,
  Divider,
  Tag,
  Spin,
  Icon,
  Popconfirm,
  message
} from 'antd'
import ProfessionalDeputySetupModal from './ProfessionDeputySetupModal'
import api from '../lib/api'
import config from '../config'
import { getUserAttributeValue } from '../lib/cognito'
import { USER_STATUSES } from '../share/constants'
import DelegateAccessModal from './DelegateAccessModal'
import { AdminContext } from './AuthLayout'

const { Item } = Descriptions

export default function ProfessionalDeputies() {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [defaultModalVisible, setDefaultModalVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [professionalDeputies, setProfessionalDeputies] = useState([])
  const [defaultProfessionalDeputy, setDefaultProfessionalDeputy] = useState({})
  const [otherProfessionalDeputies, setOtherProfessionalDeputies] = useState([])
  const [admins, setAdmins] = useState([])
  const [users, setUsers] = useState([])
  const [selectedDeputy, setSelectedDeputy] = useState({})
  const [delegateAccessModalVisible, setDelegateAccessModalVisible] =
    useState(false)

  const { professionalDeputyId } = useContext(AdminContext)
  const fetchDeputies = () => {
    setIsLoading(true)
    api
      .getProfessionalDeputies()
      .then(res => {
        if (res.data.Items?.length) {
          setProfessionalDeputies(res.data.Items)
          const defaultDeputy = res.data.Items.find(item => item.isDefault)
          if (defaultDeputy) setDefaultProfessionalDeputy(defaultDeputy)

          const otherProfessionalDeputies = res.data.Items.filter(
            item => !item.isDefault
          )
          setOtherProfessionalDeputies(otherProfessionalDeputies)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchAdmins = professionalDeputyId => {
    api
      .listAdmins(professionalDeputyId === '' ? {} : { professionalDeputyId })
      .then(res => {
        if (res.data.message) throw Error('Failed to get admins')

        if (res.data.length) {
          const admins = res.data.map(user => {
            return {
              id: user.Username,
              email: getUserAttributeValue(user.Attributes, 'email'),
              status: USER_STATUSES[user.UserStatus]
            }
          })
          setAdmins(admins)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchUsers = () => {
    api
      .listUsers({})
      .then(res => {
        if (res.data.message) throw Error(res.data.message)

        if (res.data.length) {
          const users = res.data.map(user => {
            return {
              id: user.id,
              name: user.fullName,
              email: user.email,
              createDate: user.createdAt,
              deputies: user.deputies
                ?.filter(d => d.id)
                .map(d => d.email)
                .join(', '),
              unlockStatus: user.unlockedShares
                ? `${user.unlockedShares.length}/2`
                : '0/2',
              lastLogin: user.lastLogin,
              appointedProfessionalDeputyId: user.appointedProfessionalDeputyId,
              marketingFromVaultbox: user.marketingFromVaultbox,
              marketingFromThirdParties: user.marketingFromThirdParties,
              countryName: user.countryName,
              appliedPromotionCodes: user.appliedPromotionCodes || []
            }
          })

          setUsers(users)
        }

        setIsLoading(false)
      })
      .catch(err => {
        setError(err.message || 'Failed to get users')
        setIsLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    fetchUsers()
    fetchDeputies()
    fetchAdmins()
  }, [])

  const handleRemovePdDelegation = async (userId, professionalDeputyId) => {
    try {
      await api.removePdDelegation(
        JSON.stringify({
          userId: userId,
          professionalDeputyId: professionalDeputyId
        })
      )
      message.success('Successfully removed delegation')
      fetchDeputies()
    } catch (error) {
      setError(error.message || 'Failed to remove delegation')
    }
  }
  return (
    <Spin spinning={isLoading}>
      <PageHeader
        title={
          professionalDeputyId
            ? 'Professional deputy info'
            : 'Professional deputies'
        }
      />
      {error && (
        <Alert
          type="error"
          showIcon
          message={error}
          closable
          onClose={() => setError('')}
          style={{ marginBottom: 10 }}
        />
      )}

      {(!professionalDeputyId || !!defaultProfessionalDeputy) && (
        <>
          <PageHeader
            title="Watiga Trust"
            extra={
              <Button
                type="primary"
                onClick={() => setDefaultModalVisible(true)}
              >
                Setup
              </Button>
            }
          />
          <div className="pd-description">
            <Descriptions
              bordered
              size="middle"
              style={{ marginBottom: 24 }}
              column={1}
            >
              <Item label="Referral code">{defaultProfessionalDeputy.id}</Item>
              <Item label="Deputy email addresses">
                <ul>
                  {defaultProfessionalDeputy.accounts?.map((account, index) => (
                    <li key={index}>
                      {account.email}{' '}
                      {account.userId ? (
                        <Tag color="green">Active</Tag>
                      ) : (
                        <Tag color="gold">Pending</Tag>
                      )}
                    </li>
                  ))}
                </ul>
              </Item>
              <Item label="Admin email addresses">
                <ul>
                  {defaultProfessionalDeputy.adminAccounts?.map(
                    (account, index) => (
                      <li key={index}>
                        {account.email}{' '}
                        {admins.find(
                          a =>
                            a.email === account.email &&
                            a.status === USER_STATUSES.CONFIRMED
                        ) ? (
                          <Tag color="green">{USER_STATUSES.CONFIRMED}</Tag>
                        ) : (
                          <Tag color="gold">
                            {USER_STATUSES.FORCE_CHANGE_PASSWORD}
                          </Tag>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </Item>
            </Descriptions>

            {!professionalDeputyId && <Divider />}
          </div>
        </>
      )}

      <ProfessionalDeputySetupModal
        setSelectedDeputy={setSelectedDeputy}
        listProfessionalDeputy={[
          ...otherProfessionalDeputies,
          defaultProfessionalDeputy
        ]}
        visible={defaultModalVisible}
        fetchUsers={fetchUsers}
        setVisible={setDefaultModalVisible}
        fetchDeputies={fetchDeputies}
        selectedDeputy={defaultProfessionalDeputy}
        admins={admins}
        users={users}
        isDefault
      />
      {!professionalDeputyId && (
        <PageHeader
          title="Other professional deputies"
          extra={
            <Button type="primary" onClick={() => setModalVisible(true)}>
              Add
            </Button>
          }
        />
      )}

      <div className="pd-description">
        {otherProfessionalDeputies
          .filter(pd => !professionalDeputyId || pd.id === professionalDeputyId)
          .map(deputy => (
            <Descriptions
              bordered
              title={
                <span>
                  {deputy.professionalDeputyName}
                  <Button
                    icon="edit"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      setSelectedDeputy(deputy)
                      setModalVisible(true)
                    }}
                  />
                </span>
              }
              size="middle"
              key={deputy.id}
              style={{ marginBottom: 24 }}
              column={1}
            >
              <Item label="Referral code">{deputy.id}</Item>
              <Item label="Logo">
                <img
                  src={`https://s3-${config.aws.REGION}.amazonaws.com/${config.s3.PUBLIC_BUCKET}/logo/${deputy.id}`}
                  alt="Professional Deputy logo"
                  style={{ width: 300 }}
                />
              </Item>
              <Item label="Description">{deputy.description}</Item>
              <Item label="Deputy email addresses">
                <ul>
                  {deputy.accounts?.map((account, index) => (
                    <li key={index}>
                      {account.email}{' '}
                      {account.userId ? (
                        <Tag color="green">{USER_STATUSES.CONFIRMED}</Tag>
                      ) : (
                        <Tag color="gold">
                          {USER_STATUSES.FORCE_CHANGE_PASSWORD}
                        </Tag>
                      )}
                    </li>
                  ))}
                </ul>
              </Item>
              <Item label="Delegated accounts">
                <ul>
                  {deputy.delegatedAccounts?.map((account, index) => (
                    <li key={index}>
                      {account.email}{' '}
                      {account.isAccepted ? (
                        <Tag color="green">{USER_STATUSES.CONFIRMED}</Tag>
                      ) : (
                        <Tag color="gold">
                          {USER_STATUSES.FORCE_CHANGE_PASSWORD}
                        </Tag>
                      )}
                      <Popconfirm
                        title="Are you sure to remove this account?"
                        onConfirm={() =>
                          handleRemovePdDelegation(account.userId, deputy.id)
                        }
                        okText="Yes"
                        cancelText="No"
                        arrowPointAtCenter
                        placement="bottomRight"
                      >
                        <Icon type="minus-circle" />
                      </Popconfirm>
                    </li>
                  ))}
                </ul>
                <div style={{ marginTop: 20 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedDeputy(deputy)
                      setDelegateAccessModalVisible(true)
                    }}
                  >
                    Delegate access
                  </Button>
                </div>
              </Item>
              <Item label="Admin email addresses">
                <ul>
                  {deputy.adminAccounts?.map((account, index) => (
                    <li key={index}>
                      {account.email}{' '}
                      {admins.find(
                        a =>
                          a.email === account.email &&
                          a.status === USER_STATUSES.CONFIRMED
                      ) ? (
                        <Tag color="green">{USER_STATUSES.CONFIRMED}</Tag>
                      ) : (
                        <Tag color="gold">
                          {USER_STATUSES.FORCE_CHANGE_PASSWORD}
                        </Tag>
                      )}
                    </li>
                  ))}
                </ul>
              </Item>
            </Descriptions>
          ))}
      </div>

      <ProfessionalDeputySetupModal
        setSelectedDeputy={setSelectedDeputy}
        listProfessionalDeputy={[
          ...otherProfessionalDeputies,
          defaultProfessionalDeputy
        ]}
        fetchUsers={fetchUsers}
        visible={modalVisible}
        setVisible={setModalVisible}
        fetchDeputies={fetchDeputies}
        selectedDeputy={selectedDeputy}
        admins={admins}
        users={users}
      />

      <DelegateAccessModal
        visible={delegateAccessModalVisible}
        setVisible={setDelegateAccessModalVisible}
        fetchDeputies={fetchDeputies}
        selectedDeputy={selectedDeputy}
        professionalDeputies={professionalDeputies}
      />
    </Spin>
  )
}
