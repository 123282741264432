import React, { useState, useEffect, useContext } from 'react'
import {
  Form,
  Modal,
  Alert,
  Select,
  Tooltip,
  Icon,
  message,
  Descriptions
} from 'antd'
import api from '../lib/api'
import AddMultipleFields from './AddMultipleFields'
import { AdminContext } from './AuthLayout'

function InviteUsersModal({
  visible,
  setVisible,
  form,
  discountCodeGroups,
  products
}) {
  const { professionalDeputyId } = useContext(AdminContext)
  const [err, setErr] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [professionalDeputy, setProfessionalDeputy] = useState({})
  const [pricesData, setPricesData] = useState(null)
  const { getFieldDecorator } = form

  useEffect(() => {
    if (!professionalDeputyId) {
      return
    }
    api
      .getProfessionalDeputy(professionalDeputyId)
      .then(res => {
        if (res.data.Item) setProfessionalDeputy(res.data.Item)
      })
      .catch(err => console.log(err))
  }, [professionalDeputyId])

  const sendInvitationEmails = e => {
    setErr('')
    form.validateFields((err, values) => {
      if (err) return
      if (!values.emails?.length) {
        setErr('Please enter at least 1 email address.')
        return
      }

      setIsSending(true)
      const invitedEmails = values.emails?.map(e => e.email)
      api
        .sendInvitationEmails(
          JSON.stringify({
            invitedEmails,
            professionalDeputyId: professionalDeputy.isDefault
              ? ''
              : professionalDeputyId,
            professionalDeputyName: professionalDeputy.professionalDeputyName,
            discountCode: values.discountCode
          })
        )
        .then(res => {
          message.success('Successfully sent invitation')
          reset()
        })
        .catch(err => {
          setErr(err.message)
          console.log(err)
        })
        .finally(() => setIsSending(false))
    })
  }

  const handleDiscountCodeChange = value => {
    if (!value) {
      setPricesData(null)
      return
    }

    const groupedByProduct = discountCodeGroups.find(g => g.code === value).data
    let pricesData = []
    for (const productId in groupedByProduct) {
      const productName = products.find(p => p.id === productId).name
      pricesData.push({
        productId,
        productName,
        data: groupedByProduct[productId]
      })
    }
    pricesData.sort((a, b) => b.productName.localeCompare(a.productName))

    setPricesData(pricesData)
  }

  const reset = () => {
    setErr('')
    setVisible(false)
    setPricesData(null)
    form.resetFields()
  }

  return (
    <Modal
      title="Invite users"
      visible={visible}
      okText="Send invitation"
      okButtonProps={{
        loading: isSending
      }}
      onOk={sendInvitationEmails}
      onCancel={reset}
      width={700}
    >
      <>
        <Form>
          <Form.Item label="Email addresses">
            <AddMultipleFields
              name="emails"
              title="email address"
              fields={[{ key: 'email', placeholder: 'email@example.com' }]}
              getFieldDecorator={getFieldDecorator}
              customRules={[
                {
                  fieldKey: 'email',
                  rules: [
                    {
                      type: 'email',
                      message: 'Enter a valid email address'
                    }
                    //   {
                    //     validator: (rule, value, callback) =>
                    //       compareWithCurrentUserEmail(
                    //         rule,
                    //         value,
                    //         callback,
                    //         userInfo.email
                    //       )
                    //   }
                  ]
                }
              ]}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Discount code{' '}
                <Tooltip
                  title="Select a discount code to offer discount to the invited users. Leave this blank to use the default prices."
                  overlayStyle={{ maxWidth: 300 }}
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('discountCode')(
              <Select
                allowClear
                placeholder="Select a discount code"
                onChange={handleDiscountCodeChange}
              >
                {discountCodeGroups.map(group => (
                  <Select.Option key={group.code} value={group.code}>
                    {group.code}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {pricesData &&
            pricesData.map(pd => (
              <Descriptions
                title={pd.productName}
                size="middle"
                key={pd.productId}
                style={{ marginTop: 20 }}
                column={1}
              >
                {pd.data.map(price => (
                  <Descriptions.Item key={price.id} label={price.nickname}>
                    {price.currency?.toUpperCase()} {price.unit_amount / 100}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            ))}
        </Form>
        {err && (
          <Alert
            message={err}
            type="error"
            closable
            afterClose={() => setErr('')}
          />
        )}
      </>
    </Modal>
  )
}

const WrappedInviteUsersForm = Form.create({
  name: 'InviteUsersModal'
})(InviteUsersModal)

export default WrappedInviteUsersForm
