import React from 'react'
import { Form, Input, Alert, Steps, Icon, Collapse, Button } from 'antd'
import QRCode from 'qrcode.react'
import AuthenticatorAppsTable from './AuthenticatorAppsTable'
import AuthenticatorAppsInstructions from './AuthenticatorAppsInstructions'
import TextInput from './TextInput'

const { Step } = Steps

function SetupMFA({
  username,
  secretCode,
  errMsg,
  setErrMsg,
  isSubmitting,
  verifySoftwareToken,
  form,
  currentStep,
  setCurrentStep,
  handleCancel,
  handleFinish
}) {
  const { getFieldDecorator } = form
  const { Panel } = Collapse

  let secretCodeRef

  const steps = [
    {
      title: 'Authenticator app',
      content: (
        <>
          <p>
            To set up multi-factor authentication on vaultbox, you need to have
            a compatible authenticator app on your phone.
          </p>
          <p>
            If you don't have one, you may consider installing one from the app
            store specific to your phone type.
          </p>
          <Collapse
            style={{ margin: '10px 0' }}
            expandIcon={({ isActive }) => (
              <Icon type="caret-right" rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header={<u>See the list of commonly-used authenticator apps</u>}
            >
              <AuthenticatorAppsTable />
            </Panel>
          </Collapse>
          <p>
            When you are ready with an authenticator app installed, click Next.
          </p>

          <div className="steps-actions">
            {handleCancel && <Button onClick={handleCancel}>Cancel</Button>}
            <Button type="primary" onClick={e => setCurrentStep(1)}>
              Next
            </Button>
          </div>
        </>
      )
    },
    {
      title: 'Add vaultbox to your authenticator app',
      content: (
        <>
          <p style={{ marginBottom: 10 }}>
            Use your authenticator app to scan this QR code.
          </p>

          <QRCode
            value={`otpauth://totp/${username}?secret=${secretCode}&issuer=admin-vaultbox`}
            fgColor="#006D3E"
          />

          <div>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => setCurrentStep(0)}
            >
              Need an authenticator app?
            </Button>
          </div>

          <Collapse
            expandIcon={({ isActive }) => (
              <Icon type="caret-right" rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header={
                <u>How do I scan the QR code from my authenticator app?</u>
              }
            >
              <AuthenticatorAppsInstructions />
            </Panel>
            <Panel header={<u>Show code for manual configuration</u>}>
              <Form.Item>
                <TextInput
                  ref={ref => (secretCodeRef = ref)}
                  value={secretCode}
                  style={{
                    marginRight: 10,
                    width: 'calc(100% - 60px)'
                  }}
                />
                <Button
                  onClick={e => {
                    secretCodeRef.select()
                    document.execCommand('copy')
                  }}
                  icon="copy"
                />
              </Form.Item>
            </Panel>
          </Collapse>

          <p style={{ marginTop: 10 }}>
            Ensure that you have only one vaultbox profile in your authenticator
            app. If you have more, we suggest you delete them and start again.
          </p>
          <p>
            To proceed, enter the authentication code from your authenticator
            app. This code will refresh automatically, so enter it and then
            click Next so that we can verify it.
          </p>
          <Form.Item style={{ marginTop: 10 }}>
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: 'Code is required!'
                },
                {
                  validator: (rule, value, callback) => {
                    // Code must be 6-digit number
                    const codeRegex = /^[0-9]{1,6}$/
                    if (value && !value.match(codeRegex)) {
                      callback('Code must be 6-digit number!')
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(<Input placeholder="Code" allowClear autoFocus />)}
          </Form.Item>

          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              afterClose={() => setErrMsg('')}
              closable
              style={{ marginBottom: 16 }}
            />
          )}

          <div className="steps-actions">
            <Button onClick={e => setCurrentStep(0)}>Back</Button>
            <Button
              htmlType="submit"
              type="primary"
              onClick={e => {
                form.validateFields((err, values) => {
                  if (err) return
                  verifySoftwareToken(values.code)
                })
              }}
              loading={isSubmitting}
            >
              Next
            </Button>
          </div>
        </>
      )
    },
    {
      title: 'Review',
      content: (
        <>
          <p>
            You have now set up your authenticator app for vaultbox. The next
            time you sign in, you will be asked to enter the authentication code
            from your authenticator app, in addition to your email address and
            password.
          </p>

          <div className="steps-actions">
            <Button type="primary" onClick={handleFinish}>
              Finish
            </Button>
          </div>
        </>
      )
    }
  ]

  return (
    <Form style={{ margin: 'auto', width: 600 }}>
      <h3
        style={{
          fontSize: 24
        }}
      >
        Multi-factor authentication setup
      </h3>
      <p>
        To protect your vaultbox, you need to set up multi-factor authentication
        before proceeding.
      </p>
      <a
        href="https://support.vaultbox.tech/support/solutions/articles/51000024123-setting-up-multi-factor-authentication"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more about setting up multi-factor authentication.
      </a>
      <Steps current={currentStep} className="setup-mfa-steps" style={{marginTop: 10}}>
        {steps.map(step => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>
    </Form>
  )
}

const WrappedSetupMfaForm = Form.create({ name: 'setupMfa' })(SetupMFA)
export default WrappedSetupMfaForm
