import React, { useEffect, useState, useContext } from 'react'
import api from '../lib/api'
import {
  Form,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  Table,
  PageHeader
} from 'antd'
import { disabledDate } from '../share/helpers'
import moment from 'moment'
import { AdminContext } from './AuthLayout'
import ExportExcel from './ExportExcel'

function BillingReport({ form }) {
  const [professionalDeputies, setProfessionalDeputies] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [employees, setEmployees] = useState([])

  const { professionalDeputyId } = useContext(AdminContext)

  const { getFieldDecorator, getFieldValue, getFieldsValue } = form

  useEffect(() => {
    api
      .getProfessionalDeputies()
      .then(res => {
        if (res.data?.Items?.length) setProfessionalDeputies(res.data.Items)
      })
      .catch(error => {
        console.log('Failed to get Professional deputies :>> ', error)
      })
  }, [])

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const res = await api.getEmployees(
          professionalDeputyId === '' ? {} : { professionalDeputyId }
        )

        setEmployees(res.data)
      } catch (error) {
        console.log('Failed to get employees :>>', error)
      }
    }

    fetchEmployees()
  }, [professionalDeputyId])

  const handleSearch = () => {
    form.validateFields(async (err, values) => {
      if (err) return
      setIsLoading(true)

      const pdId = values.professionalDeputy || professionalDeputyId
      const searchParams = {
        professionalDeputyId: pdId,
        dateFrom: moment(values.dateFrom).unix(),
        dateTo: moment(values.dateTo).unix(),
        isDefault: professionalDeputies.find(pd => pd.id === pdId)?.isDefault
      }

      try {
        const res = await api.getInvoicesByProfessionalDeputy(searchParams)
        setInvoices(res.data)
      } catch (error) {
        console.log('error :>> ', error)
      } finally {
        setIsLoading(false)
      }
    })
  }

  const handleReset = () => {
    form.resetFields()
    setInvoices([])
  }

  const columns = [
    {
      key: 'professionalDeputyId',
      title: 'Professional deputy referral code',
      dataIndex: 'professionalDeputyId'
    },
    {
      key: 'employee',
      title: 'Referred by employee',
      dataIndex: 'employee',
      render: (text, record) =>
        employees.find(e =>
          e.refereeUsers?.some(re => re.email === record.customer_email)
        )?.employeeName
    },
    {
      key: 'userFullName',
      title: 'User full name',
      dataIndex: 'userFullName'
    },
    {
      key: 'customer_email',
      title: 'User email address',
      dataIndex: 'customer_email'
    },
    {
      key: 'userCreatedAt',
      title: 'User created date',
      dataIndex: 'userCreatedAt',
      render: text => text && moment(text).format('LLL'),
      exportRender: text => text && new Date(text)
    },
    {
      key: 'customer',
      title: 'Stripe customer ID',
      dataIndex: 'customer'
    },
    {
      key: 'planName',
      title: 'Stripe plan name',
      render: (text, record) =>
        record.lines?.data?.find(d => d.plan)?.price?.nickname
    },
    {
      key: 'planIds',
      title: 'Stripe plan ID(s)',
      render: (text, record) => (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {record.lines?.data?.map(d => d.price.id).join('\n')}
        </div>
      ),
      exportWidth: 40,
      style: { alignment: { wrapText: true } },
      exportRender: (text, record) =>
        record.lines?.data?.map(d => d.price.id)?.join('\r\n')
    },
    {
      key: 'created',
      title: 'Invoice created date',
      dataIndex: 'created',
      render: text => text && moment(text * 1000).format('LLL'),
      exportRender: text => text && new Date(text * 1000)
    },
    {
      key: 'description',
      title: 'Invoice description',
      render: (text, record) => (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {record.lines?.data?.map(d => (
            <div key={d.id}>
              {d.description}:{' '}
              <span>
                {d.currency?.toUpperCase()} <b>{d.amount / 100}</b>
              </span>
            </div>
          ))}
        </div>
      ),
      exportWidth: 40,
      style: { alignment: { wrapText: true } },
      exportRender: (text, record) =>
        record.lines?.data
          ?.map(
            d =>
              `${d.description}: ${d.currency?.toUpperCase()} ${d.amount / 100}`
          )
          ?.join('\r\n')
    },
    {
      key: 'total',
      title: 'Invoice total amount',
      render: (text, record) => (
        <span>
          {record.currency?.toUpperCase()} <b>{record.total / 100}</b>
        </span>
      ),
      exportRender: text => text / 100
    }
  ]

  return (
    <>
      <PageHeader
        title="Billing report"
        extra={
          <ExportExcel
            fileName={'Billing report'}
            columns={columns}
            sourceData={invoices}
          />
        }
      />
      {/* {error && <Alert type="error" showIcon message={error} closable />} */}
      <div style={{ padding: 20 }}>
        <Form
          className="search-form"
          //   onKeyUp={e => (e.keyCode === 13 || e.which === 13) && handleSearch()}
        >
          <Row gutter={20}>
            {!professionalDeputyId && (
              <Col span={8}>
                <Form.Item label="Professional deputy">
                  {getFieldDecorator('professionalDeputy')(
                    <Select allowClear placeholder="Select professional deputy">
                      {professionalDeputies.map(pd => (
                        <Select.Option key={pd.id} value={pd.id}>
                          {pd.professionalDeputyName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={16}>
              <Form.Item label="Date range" style={{ marginBottom: 0 }}>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item>
                      {getFieldDecorator('dateFrom')(
                        <DatePicker
                          disabledDate={from =>
                            disabledDate(from, getFieldValue('dateTo'))
                          }
                          placeholder="From"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      {getFieldDecorator('dateTo')(
                        <DatePicker
                          disabledDate={to =>
                            disabledDate(getFieldValue('dateFrom'), to)
                          }
                          placeholder="To"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              onClick={handleSearch}
              loading={isLoading}
              disabled={Object.values(getFieldsValue()).some(v => !v)}
            >
              Search
            </Button>
            &nbsp;
            <Button type="default" onClick={handleReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={invoices}
          scroll={{ x: true }}
        />
      </div>
    </>
  )
}

const WrappedBillingReport = Form.create({ name: 'BillingReport' })(
  BillingReport
)
export default WrappedBillingReport
