import moment from 'moment'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { DATE_TIME_FORMAT, EXPORT_DATE_TIME_FORMAT } from './constants'
import sanitizeHtml from 'sanitize-html'

export const disabledDate = (startValue, endValue) => {
  if (!startValue || !endValue) {
    return false
  }
  return startValue.startOf('day').isSameOrAfter(endValue.endOf('day'))
}

export const includesCaseInsensitive = (string, subString) =>
  string?.toLowerCase().includes(subString.trim().toLowerCase())

const dataToExport = (columns, data) => {
  return data.map(row =>
    columns
      .filter(cl => cl.key !== 'actions')
      .reduce(
        (record, col) => ({
          ...record,
          [col.key]: col.exportRender
            ? col.exportRender(row[col.key], row)
            : col.render
            ? col.render(row[col.key], row)
            : row[col.key]
        }),
        {}
      )
  )
}

const generateExcelData = async (
  fileName,
  columns,
  sourceData,
  exportDate,
  extension = 'xlsx'
) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet(fileName)

  const dateString = `&L${moment(exportDate).format(DATE_TIME_FORMAT)}`

  worksheet.columns = columns.map(c => ({
    header:
      c.key === 'usedStorage'
        ? 'Used Storage (kB)'
        : c.key === 'total'
        ? 'Invoice total amount (USD)'
        : c.title,
    key: c.key,
    width: c.exportWidth,
    style: c.style
  }))
  worksheet.addRows(dataToExport(columns, sourceData))
  worksheet.headerFooter.oddHeader = worksheet.headerFooter.oddFooter =
    dateString
  return await workbook[extension].writeBuffer()
}

export const exportToExcel = (
  fileName,
  columns,
  sourceData,
  withExportDate = true,
  extension = 'xlsx'
) => {
  const exportDate = new Date()
  const exportFileName = withExportDate
    ? `${fileName}_${moment(exportDate).format(
        EXPORT_DATE_TIME_FORMAT
      )}.${extension}`
    : `${fileName}.${extension}`

  generateExcelData(fileName, columns, sourceData, exportDate, extension)
    .then(data => {
      const blob = new Blob([data])
      saveAs(blob, exportFileName)
    })
    .catch(error => {
      console.log(error)
    })
}

export const removeHtmlTags = values => {
  Object.entries(values).forEach(entry => {
    if (typeof entry[1] === 'string') {
      values[entry[0]] = sanitizeValue(entry[1])
    }

    if (Array.isArray(entry[1])) {
      entry[1].forEach((v, i) => {
        if (typeof v === 'object' && !Array.isArray(v)) {
          removeHtmlTags(v)
        }

        if (typeof v === 'string') {
          entry[1][i] = sanitizeValue(v)
        }
      })
    }
  })
  return values
}

export const sanitizeValue = value => {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {}
  })
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
